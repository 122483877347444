import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  allSummainfo?: Maybe<SummaConnection>;
  /** The ID of the object */
  summainfo?: Maybe<Summa>;
};


export type QueryAllSummainfoArgs = {
  Country?: InputMaybe<Scalars['String']>;
  Dimension?: InputMaybe<Scalars['String']>;
  Indicator?: InputMaybe<Scalars['String']>;
  Scope?: InputMaybe<Scalars['String']>;
  Serial?: InputMaybe<Scalars['String']>;
  Source?: InputMaybe<Scalars['String']>;
  TypesOfVisualization?: InputMaybe<Scalars['String']>;
  UnitOfMeasure?: InputMaybe<Scalars['String']>;
  Variable?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  y2000?: InputMaybe<Scalars['String']>;
  y2001?: InputMaybe<Scalars['String']>;
  y2002?: InputMaybe<Scalars['String']>;
  y2003?: InputMaybe<Scalars['String']>;
  y2004?: InputMaybe<Scalars['String']>;
  y2005?: InputMaybe<Scalars['String']>;
  y2006?: InputMaybe<Scalars['String']>;
  y2007?: InputMaybe<Scalars['String']>;
  y2008?: InputMaybe<Scalars['String']>;
  y2009?: InputMaybe<Scalars['String']>;
  y2010?: InputMaybe<Scalars['String']>;
  y2011?: InputMaybe<Scalars['String']>;
  y2012?: InputMaybe<Scalars['String']>;
  y2013?: InputMaybe<Scalars['String']>;
  y2014?: InputMaybe<Scalars['String']>;
  y2015?: InputMaybe<Scalars['String']>;
  y2016?: InputMaybe<Scalars['String']>;
  y2017?: InputMaybe<Scalars['String']>;
  y2018?: InputMaybe<Scalars['String']>;
  y2019?: InputMaybe<Scalars['String']>;
  y2020?: InputMaybe<Scalars['String']>;
  y2021?: InputMaybe<Scalars['String']>;
  y2022?: InputMaybe<Scalars['String']>;
  y2023?: InputMaybe<Scalars['String']>;
  y2024?: InputMaybe<Scalars['String']>;
  y2025?: InputMaybe<Scalars['String']>;
  y2026?: InputMaybe<Scalars['String']>;
  y2027?: InputMaybe<Scalars['String']>;
  y2028?: InputMaybe<Scalars['String']>;
  y2029?: InputMaybe<Scalars['String']>;
  y2030?: InputMaybe<Scalars['String']>;
  y2031?: InputMaybe<Scalars['String']>;
  y2032?: InputMaybe<Scalars['String']>;
  y2033?: InputMaybe<Scalars['String']>;
  y2034?: InputMaybe<Scalars['String']>;
  y2035?: InputMaybe<Scalars['String']>;
  y2036?: InputMaybe<Scalars['String']>;
  y2037?: InputMaybe<Scalars['String']>;
  y2038?: InputMaybe<Scalars['String']>;
  y2039?: InputMaybe<Scalars['String']>;
  y2040?: InputMaybe<Scalars['String']>;
  y2041?: InputMaybe<Scalars['String']>;
  y2042?: InputMaybe<Scalars['String']>;
  y2043?: InputMaybe<Scalars['String']>;
  y2044?: InputMaybe<Scalars['String']>;
  y2045?: InputMaybe<Scalars['String']>;
  y2046?: InputMaybe<Scalars['String']>;
  y2047?: InputMaybe<Scalars['String']>;
  y2048?: InputMaybe<Scalars['String']>;
  y2049?: InputMaybe<Scalars['String']>;
  y2050?: InputMaybe<Scalars['String']>;
};


export type QuerySummainfoArgs = {
  id: Scalars['ID'];
};

export type Summa = Node & {
  __typename?: 'Summa';
  Country?: Maybe<Scalars['String']>;
  Dimension?: Maybe<Scalars['String']>;
  IdSumma: Scalars['Int'];
  Indicator?: Maybe<Scalars['String']>;
  Scope?: Maybe<Scalars['String']>;
  Serial?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  TypesOfVisualization?: Maybe<Scalars['String']>;
  UnitOfMeasure?: Maybe<Scalars['String']>;
  Variable?: Maybe<Scalars['String']>;
  /** The ID of the object. */
  id: Scalars['ID'];
  y2000?: Maybe<Scalars['String']>;
  y2001?: Maybe<Scalars['String']>;
  y2002?: Maybe<Scalars['String']>;
  y2003?: Maybe<Scalars['String']>;
  y2004?: Maybe<Scalars['String']>;
  y2005?: Maybe<Scalars['String']>;
  y2006?: Maybe<Scalars['String']>;
  y2007?: Maybe<Scalars['String']>;
  y2008?: Maybe<Scalars['String']>;
  y2009?: Maybe<Scalars['String']>;
  y2010?: Maybe<Scalars['String']>;
  y2011?: Maybe<Scalars['String']>;
  y2012?: Maybe<Scalars['String']>;
  y2013?: Maybe<Scalars['String']>;
  y2014?: Maybe<Scalars['String']>;
  y2015?: Maybe<Scalars['String']>;
  y2016?: Maybe<Scalars['String']>;
  y2017?: Maybe<Scalars['String']>;
  y2018?: Maybe<Scalars['String']>;
  y2019?: Maybe<Scalars['String']>;
  y2020?: Maybe<Scalars['String']>;
  y2021?: Maybe<Scalars['String']>;
  y2022?: Maybe<Scalars['String']>;
  y2023?: Maybe<Scalars['String']>;
  y2024?: Maybe<Scalars['String']>;
  y2025?: Maybe<Scalars['String']>;
  y2026?: Maybe<Scalars['String']>;
  y2027?: Maybe<Scalars['String']>;
  y2028?: Maybe<Scalars['String']>;
  y2029?: Maybe<Scalars['String']>;
  y2030?: Maybe<Scalars['String']>;
  y2031?: Maybe<Scalars['String']>;
  y2032?: Maybe<Scalars['String']>;
  y2033?: Maybe<Scalars['String']>;
  y2034?: Maybe<Scalars['String']>;
  y2035?: Maybe<Scalars['String']>;
  y2036?: Maybe<Scalars['String']>;
  y2037?: Maybe<Scalars['String']>;
  y2038?: Maybe<Scalars['String']>;
  y2039?: Maybe<Scalars['String']>;
  y2040?: Maybe<Scalars['String']>;
  y2041?: Maybe<Scalars['String']>;
  y2042?: Maybe<Scalars['String']>;
  y2043?: Maybe<Scalars['String']>;
  y2044?: Maybe<Scalars['String']>;
  y2045?: Maybe<Scalars['String']>;
  y2046?: Maybe<Scalars['String']>;
  y2047?: Maybe<Scalars['String']>;
  y2048?: Maybe<Scalars['String']>;
  y2049?: Maybe<Scalars['String']>;
  y2050?: Maybe<Scalars['String']>;
};

export type SummaConnection = {
  __typename?: 'SummaConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SummaEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Summa` and its cursor. */
export type SummaEdge = {
  __typename?: 'SummaEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<Summa>;
};

export type AllSummainfoQueryVariables = Exact<{
  country: Scalars['String'];
  dimension: Scalars['String'];
  variable: Scalars['String'];
  indicator: Scalars['String'];
}>;


export type AllSummainfoQuery = { __typename?: 'Query', allSummainfo?: { __typename?: 'SummaConnection', edges: Array<{ __typename?: 'SummaEdge', node?: { __typename?: 'Summa', Country?: string | null, Dimension?: string | null, Variable?: string | null, Indicator?: string | null, TypesOfVisualization?: string | null, UnitOfMeasure?: string | null, Serial?: string | null, Source?: string | null, Scope?: string | null, y2000?: string | null, y2001?: string | null, y2002?: string | null, y2003?: string | null, y2004?: string | null, y2005?: string | null, y2006?: string | null, y2007?: string | null, y2008?: string | null, y2009?: string | null, y2010?: string | null, y2011?: string | null, y2012?: string | null, y2013?: string | null, y2014?: string | null, y2015?: string | null, y2016?: string | null, y2017?: string | null, y2018?: string | null, y2019?: string | null, y2020?: string | null, y2021?: string | null, y2022?: string | null, y2023?: string | null, y2024?: string | null, y2025?: string | null, y2026?: string | null, y2027?: string | null, y2028?: string | null, y2029?: string | null, y2030?: string | null, y2031?: string | null, y2032?: string | null, y2033?: string | null, y2034?: string | null, y2035?: string | null, y2036?: string | null, y2037?: string | null, y2038?: string | null, y2039?: string | null, y2040?: string | null, y2041?: string | null, y2042?: string | null, y2043?: string | null, y2044?: string | null, y2045?: string | null, y2046?: string | null, y2047?: string | null, y2048?: string | null, y2049?: string | null, y2050?: string | null } | null } | null> } | null };

export type AllIndicatorsQueryVariables = Exact<{
  dimension: Scalars['String'];
  variable: Scalars['String'];
}>;


export type AllIndicatorsQuery = { __typename?: 'Query', allSummainfo?: { __typename?: 'SummaConnection', edges: Array<{ __typename?: 'SummaEdge', node?: { __typename?: 'Summa', Indicator?: string | null } | null } | null> } | null };


export const AllSummainfoDocument = gql`
    query allSummainfo($country: String!, $dimension: String!, $variable: String!, $indicator: String!) {
  allSummainfo(
    Country: $country
    Dimension: $dimension
    Variable: $variable
    Indicator: $indicator
  ) {
    edges {
      node {
        Country
        Dimension
        Variable
        Indicator
        TypesOfVisualization
        UnitOfMeasure
        Serial
        Source
        Scope
        y2000
        y2001
        y2002
        y2003
        y2004
        y2005
        y2006
        y2007
        y2008
        y2009
        y2010
        y2011
        y2012
        y2013
        y2014
        y2015
        y2016
        y2017
        y2018
        y2019
        y2020
        y2021
        y2022
        y2023
        y2024
        y2025
        y2026
        y2027
        y2028
        y2029
        y2030
        y2031
        y2032
        y2033
        y2034
        y2035
        y2036
        y2037
        y2038
        y2039
        y2040
        y2041
        y2042
        y2043
        y2044
        y2045
        y2046
        y2047
        y2048
        y2049
        y2050
      }
    }
  }
}
    `;

/**
 * __useAllSummainfoQuery__
 *
 * To run a query within a React component, call `useAllSummainfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSummainfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSummainfoQuery({
 *   variables: {
 *      country: // value for 'country'
 *      dimension: // value for 'dimension'
 *      variable: // value for 'variable'
 *      indicator: // value for 'indicator'
 *   },
 * });
 */
export function useAllSummainfoQuery(baseOptions: Apollo.QueryHookOptions<AllSummainfoQuery, AllSummainfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSummainfoQuery, AllSummainfoQueryVariables>(AllSummainfoDocument, options);
      }
export function useAllSummainfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSummainfoQuery, AllSummainfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSummainfoQuery, AllSummainfoQueryVariables>(AllSummainfoDocument, options);
        }
export type AllSummainfoQueryHookResult = ReturnType<typeof useAllSummainfoQuery>;
export type AllSummainfoLazyQueryHookResult = ReturnType<typeof useAllSummainfoLazyQuery>;
export type AllSummainfoQueryResult = Apollo.QueryResult<AllSummainfoQuery, AllSummainfoQueryVariables>;
export const AllIndicatorsDocument = gql`
    query allIndicators($dimension: String!, $variable: String!) {
  allSummainfo(Dimension: $dimension, Variable: $variable) {
    edges {
      node {
        Indicator
      }
    }
  }
}
    `;

/**
 * __useAllIndicatorsQuery__
 *
 * To run a query within a React component, call `useAllIndicatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllIndicatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllIndicatorsQuery({
 *   variables: {
 *      dimension: // value for 'dimension'
 *      variable: // value for 'variable'
 *   },
 * });
 */
export function useAllIndicatorsQuery(baseOptions: Apollo.QueryHookOptions<AllIndicatorsQuery, AllIndicatorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllIndicatorsQuery, AllIndicatorsQueryVariables>(AllIndicatorsDocument, options);
      }
export function useAllIndicatorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllIndicatorsQuery, AllIndicatorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllIndicatorsQuery, AllIndicatorsQueryVariables>(AllIndicatorsDocument, options);
        }
export type AllIndicatorsQueryHookResult = ReturnType<typeof useAllIndicatorsQuery>;
export type AllIndicatorsLazyQueryHookResult = ReturnType<typeof useAllIndicatorsLazyQuery>;
export type AllIndicatorsQueryResult = Apollo.QueryResult<AllIndicatorsQuery, AllIndicatorsQueryVariables>;