import * as React from "react";
import {
  useAllIndicatorsQuery,
  useAllSummainfoQuery,
} from "../../generated/graphql";
import Charts from "./Charts";
import Dimensions from "../../utils/dimensions.json";
import Variables from "../../utils/variables.json";
import Countries from "../../utils/countries.json";
import { MultiValue, SingleValue } from "react-select";
import { useEffect } from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import translations from "../../utils/i18n.json";

i18n.use(initReactI18next).init({
  resources: translations,
  lng: new URLSearchParams(window.location.search).get("lang")?.toString(),
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

const ChartsContainer = () => {
  const { t } = useTranslation();

  const [shareText, setShareText] = React.useState("Share");
  const [dimension, setDimension] = React.useState<{
    label: string;
    value: string;
  } | null>(
    new URLSearchParams(window.location.search).get("dimension")
      ? {
          label:
            new URLSearchParams(window.location.search).get("dimension") || "",
          value:
            new URLSearchParams(window.location.search).get("dimension") || "",
        }
      : null
  );
  const [filteredVariables, setFilteredVariables] = React.useState<
    | {
        label: string;
        value: string;
        dimension: string;
      }[]
    | null
  >(null);
  const [indicator, setIndicator] = React.useState<{
    label: string;
    value: string;
  } | null>(
    new URLSearchParams(window.location.search).get("indicator")
      ? {
          label:
            new URLSearchParams(window.location.search).get("indicator") || "",
          value:
            new URLSearchParams(window.location.search).get("indicator") || "",
        }
      : null
  );
  const [variable, setVariable] = React.useState<{
    label: string;
    value: string;
  } | null>(
    new URLSearchParams(window.location.search).get("variable")
      ? {
          label:
            new URLSearchParams(window.location.search).get("variable") || "",
          value:
            new URLSearchParams(window.location.search).get("variable") || "",
        }
      : null
  );

  const [selectedCountries, setSelectedCountries] = React.useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(
    new URLSearchParams(window.location.search)
      .get("countries")
      ?.split(",")
      .map((c) => {
        return { label: c, value: c };
      }) || null
  );

  const currentYear = new Date().getFullYear();

  const [years, setYears] = React.useState<number[]>(
    new URLSearchParams(window.location.search)
      .get("years")
      ?.split(",")
      .map((y) => {
        return Number(y);
      }) || [2000, currentYear]
  );

  const { data: summaData, refetch: refetchSumma } = useAllSummainfoQuery({
    variables: {
      dimension: "null",
      variable: "null",
      country: "null",
      indicator: "null",
    },
  });

  useEffect(() => {
    if (dimension) {
      setFilteredVariables(
        Variables.filter((v) => v.dimension === dimension.value)
      );
      if (variable) {
        refetchIndicators({
          dimension: dimension?.value,
          variable: variable?.value,
        });
      }
    }
    refetchSumma({
      dimension: dimension?.value || "null",
      variable: variable?.value || "null",
      indicator: indicator?.value || "null",
      country: selectedCountries && selectedCountries.length > 0 ? "" : "null",
    });
    // eslint-disable-next-line
  }, []);

  const insertUrlParam = (key: any, value: any) => {
    if (window.history.pushState) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      let newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };

  const handleDimensionChange = (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => {
    if (newValue) {
      setDimension(newValue);
      setVariable(null);
      setIndicator(null);
      setFilteredVariables(
        Variables.filter((v) => v.dimension === newValue.value)
      );
      refetchIndicators({ dimension: "null", variable: "null" });
      insertUrlParam("dimension", newValue.value);
    }
  };

  const handleVariableChange = (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => {
    if (newValue) {
      setVariable(newValue);
      setIndicator(null);
      refetchIndicators({
        dimension: dimension?.value,
        variable: newValue.value,
      });
      insertUrlParam("variable", newValue.value);
    }
  };

  const handleIndicatorChange = (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => {
    if (newValue) {
      setIndicator(newValue);
      refetchSumma({
        dimension: "null",
        variable: "null",
        indicator: "null",
        country: "null",
      });
      setSelectedCountries(null);
      insertUrlParam("indicator", newValue.value);
    }
  };

  useEffect(() => {
    if (selectedCountries && selectedCountries.length > 0) {
      insertUrlParam(
        "countries",
        selectedCountries.map((n: any) => n.value).toString()
      );
    }
  }, [selectedCountries]);

  const handleYearsChange = (values: number[]) => {
    if (values) {
      setYears(values);
      insertUrlParam("years", values.toString());
    }
  };

  const { data: indicatorsData, refetch: refetchIndicators } =
    useAllIndicatorsQuery({
      variables: { dimension: "null", variable: "null" },
    });

  const handleSubmitClick = () => {
    refetchSumma({
      dimension: dimension?.value,
      variable: variable?.value,
      indicator: indicator?.value,
      country: "",
    });
  };

  const handleShareClick = () => {
    setShareText("Copied!");
    navigator.clipboard.writeText(window.location.href);
    setTimeout(() => {
      setShareText("Share");
    }, 2000);
  };

  return (
    <Charts
      dimensions={Dimensions.sort((a, b) =>
        t(a.label).localeCompare(t(b.label))
      ).map((d) => {
        return { label: t(d.label), value: d.value };
      })}
      variables={filteredVariables
        ?.sort((a, b) => t(a.label).localeCompare(t(b.label)))
        .map((v) => {
          return { label: t(v.label), value: v.value, dimension: v.dimension };
        })}
      countries={Countries.sort((a, b) =>
        t(a.label).localeCompare(t(b.label))
      ).map((c) => {
        return { label: t(c.label), value: c.value };
      })}
      selectedDimension={
        dimension
          ? {
              label: t(dimension.label),
              value: dimension.value,
            }
          : null
      }
      selectedVariable={
        variable ? { label: t(variable.label), value: variable.value } : null
      }
      selectedIndicator={
        indicator ? { label: t(indicator.label), value: indicator.value } : null
      }
      allIndicators={indicatorsData}
      handleDimensionChange={handleDimensionChange}
      handleVariableChange={handleVariableChange}
      handleIndicatorChange={handleIndicatorChange}
      years={years}
      handleYearsChange={handleYearsChange}
      handleSubmitClick={handleSubmitClick}
      handleShareClick={handleShareClick}
      allData={summaData}
      shareText={shareText}
      t={t}
      selectedCountries={selectedCountries}
      onChangeCountry={setSelectedCountries}
    />
  );
};

export default ChartsContainer;
