import * as React from "react";
import { AllIndicatorsQuery, AllSummainfoQuery } from "../../generated/graphql";
import "./styles.css";
import Dimensions from "../../utils/dimensions.json";
import Variables from "../../utils/variables.json";
import Countries from "../../utils/countries.json";
import { Range, getTrackBackground, useThumbOverlap } from "react-range";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HC_exporting2 from "highcharts/modules/export-data";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import Select, { MultiValue, SingleValue } from "react-select";
import { TFunction } from "react-i18next";
import { useRef } from "react";

interface Props {
  dimensions: typeof Dimensions;
  variables: typeof Variables | undefined;
  countries: typeof Countries;
  allIndicators: AllIndicatorsQuery | undefined;
  allData: AllSummainfoQuery | undefined;
  years: number[];
  handleYearsChange: (values: number[]) => void;
  handleDimensionChange: (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => void;
  handleVariableChange: (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => void;
  handleIndicatorChange: (
    newValue: SingleValue<{
      label: string;
      value: string;
    }>
  ) => void;
  handleSubmitClick: () => void;
  handleShareClick: () => void;
  selectedVariable: { value: string; label: string } | null;
  selectedIndicator: { value: string; label: string } | null;
  selectedDimension: { value: string; label: string } | null;
  shareText: string;
  t: TFunction<"translation", undefined>;
  selectedCountries: any;
  onChangeCountry: any;
}

const currentYear = new Date().getFullYear();

const COLORS = ["lightgray", "#2C9CC1", "lightgray"];
const THUMB_SIZE = 42;
const MIN = 2000;
const MAX = currentYear;

function allAreNull(arr: any) {
  return arr.every((element: any) => element === null);
}

function ThumbLabel({
  rangeRef,
  values,
  index,
}: {
  rangeRef: Range | null;
  values: number[];
  index: number;
}) {
  const [labelValue, labelStyle] = useThumbOverlap(
    rangeRef,
    values,
    index,
    1,
    " - ",
    (value) => `${value}`
  );
  return (
    <div
      data-label={index}
      style={{
        display: "block",
        position: "absolute",
        top: "-28px",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "14px",
        padding: "4px",
        borderRadius: "4px",
        backgroundColor: "#2C9CC1",
        whiteSpace: "nowrap",
        ...(labelStyle as React.CSSProperties),
      }}
    >
      {labelValue}
    </div>
  );
}

const Charts: React.FC<Props> = ({
  dimensions,
  variables,
  countries,
  handleDimensionChange,
  handleVariableChange,
  handleIndicatorChange,
  allIndicators,
  allData,
  years,
  handleYearsChange,
  handleSubmitClick,
  handleShareClick,
  selectedVariable,
  selectedIndicator,
  selectedDimension,
  shareText,
  t,
  selectedCountries,
  onChangeCountry,
}) => {
  const lang = new URLSearchParams(window.location.search)
    .get("lang")
    ?.toString();
  let source = "";
  switch (lang) {
    case "es":
      source = "https://edudata.summaedu.org/glosario-summa/";
      break;
    case "en":
      source = "https://edudata.summaedu.org/en/glossary-summa/";
      break;
    case "fr":
      source = "https://edudata.summaedu.org/fr/glossaire/";
      break;

    default:
      source = "https://edudata.summaedu.org/en/glossary-summa/";
      break;
  }
  HC_exporting(Highcharts);
  HC_exporting2(Highcharts);
  NoDataToDisplay(Highcharts);
  Highcharts.theme = {
    chart: {
      style: {
        fontSize: "14px",
        color: "#0A445B",
      },
    },
    title: {
      style: {
        fontSize: "25px",
        color: "#0A445B",
      },
    },
    subtitle: {
      style: {
        fontSize: "14px",
        color: "#0A445B",
      },
    },
    legend: {
      itemStyle: {
        fontSize: "14px",
        color: "#0A445B",
        fontWeight: "normal",
      },
      itemHoverStyle: {
        fontWeight: "bold",
      },
    },
    lang: {
      decimalPoint: ",",
      thousandsSep: ".",
    },
    xAxis: { labels: { style: { fontSize: "14px", color: "#0A445B" } } },
    yAxis: {
      labels: { style: { fontSize: "14px", color: "#0A445B" } },
      title: { style: { fontSize: "14px", color: "#0A445B" } },
    },
    noData: {
      style: { fontSize: "14px", color: "#0A445B", fontWeight: "normal" },
    },
    credits: { style: { fontSize: "12px", color: "#0A445B" } },
  };
  Highcharts.setOptions(Highcharts.theme);

  let indicators: any = [];

  if (!!allIndicators?.allSummainfo) {
    indicators = allIndicators.allSummainfo.edges.map((node, i) => {
      const text = !!node
        ? node.node
          ? (node.node.Indicator?.trim() as string)
          : ""
        : "";
      return {
        label: t(text),
        value: text,
      };
    });
  }

  indicators = Array.from(
    new Set(
      indicators
        .sort((a: any, b: any) => a.label.localeCompare(b.label))
        .map((a: any) => a.label)
    )
  ).map((id) => {
    return indicators.find((a: any) => a.label === id);
  });

  let data: any[] = [];

  if (!!allData?.allSummainfo) {
    data = allData.allSummainfo.edges.map((node, i) =>
      !!node ? (node.node ? node.node : "") : ""
    );
  }

  let options: Highcharts.Options[] = [];

  let rows: any = [];
  if (data.length) {
    let range: any = [];
    data
      .filter((row) =>
        selectedCountries?.map((c: any) => c.value).includes(row.Country)
      )
      .forEach((row) => {
        let list: any = [];
        for (var i = years[0]; i <= years[1]; i++) {
          list.push(
            row["y" + i.toString()]
              ? parseFloat(row["y" + i.toString()].replace(",", "."))
              : null
          );

          range.push(i.toString());
        }
        rows.push({
          data: list,
          TypesOfVisualization: t(row.TypesOfVisualization),
          Indicator: t(row.Indicator),
          Country: t(row.Country),
          Serial: t(row.Serial),
          Source: t(row.Source),
          UnitOfMeasure: t(row.UnitOfMeasure),
          Scope: t(row.Scope),
        });
      });

    if (rows.length > 0) {
      if (rows[0].Scope === "National") {
        selectedCountries
          ?.map((c: any) => c.value)
          .forEach((country: any) => {
            const currentCountry = rows.filter(
              (row: any) => row.Country === t(country)
            )[0];

            options.push({
              title: {
                text: `${currentCountry?.Indicator} - ${t(country)}`,
              },
              subtitle: {
                text: years[0].toString() + " - " + years[1].toString(),
              },
              series: rows
                .filter((row: any) => row.Country === t(country))
                .map((row: any) => ({
                  type:
                    row.TypesOfVisualization === "Pyramid graph"
                      ? "pyramid"
                      : row.TypesOfVisualization === "Bar Graph"
                      ? "bar"
                      : "line",
                  data: allAreNull(row.data) ? [] : row.data,
                  name: row.Serial,
                })),
              yAxis: {
                title: {
                  text: `${currentCountry?.UnitOfMeasure || ""}`,
                },
              },
              xAxis: {
                allowDecimals: false,
                categories: range,
              },
              credits: {
                text: `${t("Source: SUMMA based on")} ${
                  currentCountry?.Source
                }`,
                href: `javascript:window.open("${source}", "_blank")`,
              },
              chart: {
                style: { fontFamily: "'Lato', sans-serif" },
              },
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [
                      "printChart",
                      "separator",
                      "downloadPNG",
                      "downloadJPEG",
                      "downloadPDF",
                      "downloadSVG",
                      "separator",
                      "downloadCSV",
                      "downloadXLS",
                    ],
                  },
                },
              },
              lang: {
                printChart: t("Print chart"),
                downloadPNG: t("Download PNG image"),
                downloadJPEG: t("Download JPEG image"),
                downloadPDF: t("Download PDF document"),
                downloadSVG: t("Download SVG vector image"),
                downloadCSV: t("Download CSV"),
                downloadXLS: t("Download XLS"),
                noData: t("No data"),
              },
            });
          });
      } else if (rows[0].Scope === "International") {
        options.push({
          title: {
            text: rows[0].Indicator,
          },
          subtitle: {
            text: years[0].toString() + " - " + years[1].toString(),
          },
          series: rows.map((row: any) => ({
            type:
              row.TypesOfVisualization === "Pyramid graph"
                ? "pyramid"
                : row.TypesOfVisualization === "Bar Graph"
                ? "bar"
                : "line",
            data: allAreNull(row.data) ? [] : row.data,
            name: row.Country,
          })),
          yAxis: {
            title: {
              text: rows[0].UnitOfMeasure,
            },
          },
          xAxis: {
            allowDecimals: false,
            categories: range,
          },
          credits: {
            text: `${t("Source: SUMMA based on")} ${rows[0].Source}`,
            href: `javascript:window.open("${source}", "_blank")`,
          },
          chart: { style: { fontFamily: "'Lato', sans-serif" } },
          exporting: {
            buttons: {
              contextButton: {
                menuItems: [
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                  "separator",
                  "downloadCSV",
                  "downloadXLS",
                ],
              },
            },
          },
          lang: {
            printChart: t("Print chart"),
            downloadPNG: t("Download PNG image"),
            downloadJPEG: t("Download JPEG image"),
            downloadPDF: t("Download PDF document"),
            downloadSVG: t("Download SVG vector image"),
            downloadCSV: t("Download CSV"),
            downloadXLS: t("Download XLS"),
            noData: t("No data"),
          },
        });
      }
    }
  }
  const rangeRef: any = React.useRef<Range>();
  const Thumb = ({ props, index, isDragged }: any) => (
    <div
      {...props}
      style={{
        ...props.style,
        height: `${THUMB_SIZE}px`,
        width: `${THUMB_SIZE}px`,
        borderRadius: "4px",
        backgroundColor: "#FFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 2px 6px #AAA",
      }}
    >
      <ThumbLabel rangeRef={rangeRef.current} values={years} index={index} />
      <div
        style={{
          height: "16px",
          width: "5px",
          backgroundColor: isDragged ? "#2C9CC1" : "lightgray",
        }}
      />
    </div>
  );
  const Track = ({ props, children }: any) => (
    <div
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      style={{
        ...props.style,
        height: "36px",
        display: "flex",
        width: "100%",
      }}
    >
      <div
        ref={props.ref}
        style={{
          height: "5px",
          width: "100%",
          borderRadius: "4px",
          background: getTrackBackground({
            values: years,
            colors: COLORS,
            min: MIN,
            max: MAX,
            // rtl,
          }),
          alignSelf: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
  const customStyles = {
    container: (provided: any, state: any) => ({
      ...provided,
      color: "#0a445b",
      fontSize: "15px",
      fontFamily: "'Lato', sans-serif",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      color: "#0a445b",
      fontSize: "15px",
      fontFamily: "'Lato', sans-serif",
    }),
    valueContainer: (provided: any, state: any) => ({
      ...provided,
      color: "#0a445b",
      fontSize: "15px",
      fontFamily: "'Lato', sans-serif",
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      color: "#0a445b",
      fontSize: "15px",
      fontFamily: "'Lato', sans-serif",
    }),
    input: (provided: any, state: any) => ({
      ...provided,
      color: "#0a445b",
      fontSize: "15px",
      fontFamily: "'Lato', sans-serif",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      color: "#0a445b",
      fontSize: "15px",
      fontFamily: "'Lato', sans-serif",
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: "#0a445b",
      fontSize: "15px",
      fontFamily: "'Lato', sans-serif",
    }),
  };

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: t("All countries"),
  };
  const valueRef = useRef(selectedCountries);
  valueRef.current = selectedCountries;

  const isSelectAllSelected = () =>
    valueRef.current?.length === countries.length;

  const isOptionSelected = (option: any) =>
    valueRef.current?.some(
      ({ value }: { value: any }) => value === option.value
    ) || isSelectAllSelected();

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : selectedCountries;

  const onChange = (newValue: any, actionMeta: any) => {
    const { action, option, removedValue } = actionMeta;

    if (action === "select-option" && option.value === selectAllOption.value) {
      onChangeCountry(countries, actionMeta);
    } else if (
      (action === "deselect-option" &&
        option.value === selectAllOption.value) ||
      (action === "remove-value" &&
        removedValue.value === selectAllOption.value)
    ) {
      onChangeCountry([], actionMeta);
    } else if (
      actionMeta.action === "deselect-option" &&
      isSelectAllSelected()
    ) {
      onChangeCountry(
        countries.filter(({ value }) => value !== option.value),
        actionMeta
      );
    } else {
      onChangeCountry(newValue || [], actionMeta);
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div>
          <Select
            onChange={handleDimensionChange}
            placeholder={t("Select dimension")}
            value={selectedDimension}
            options={dimensions}
            styles={customStyles}
          />
        </div>
        <div>
          <Select
            onChange={handleVariableChange}
            placeholder={t("Select variable")}
            value={selectedVariable}
            options={variables ? variables : []}
            styles={customStyles}
          />
        </div>
      </div>
      <div className="row">
        <div>
          <Select
            onChange={handleIndicatorChange}
            placeholder={t("Select indicator")}
            value={selectedIndicator}
            options={indicators}
            styles={customStyles}
          />
        </div>
        <div>
          <Select
            placeholder={t("Select countries")}
            isMulti
            value={getValue()}
            options={[selectAllOption, ...countries]}
            styles={customStyles}
            isOptionSelected={isOptionSelected}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="range">
        <div>{t("Range of years")}</div>
        <Range
          values={years}
          step={1}
          onChange={handleYearsChange}
          ref={rangeRef}
          renderThumb={Thumb}
          renderTrack={Track}
          min={MIN}
          max={MAX}
        />
      </div>

      <div className="buttonWrapper">
        <div className="submit" onClick={handleSubmitClick}>
          {t("Submit")}
        </div>
        {options.length > 0 && (
          <div className="share" onClick={handleShareClick}>
            {t(shareText)}
          </div>
        )}
      </div>

      {options.map((option, i) => {
        return (
          <div className="chartContainer">
            <HighchartsReact
              key={i}
              highcharts={Highcharts}
              options={option}
              containerProps={{ style: { height: "100%" } }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Charts;
